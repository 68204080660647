<template>
    <transition appear @enter="onEnter">
        <svg
            id="pepper"
            ref="pepper"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            :width="size"
            :height="size"
            version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 235.57 222.21"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            class="hero"
        >
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2586495408816">
                    <path
                        ref="stalk"
                        class="stalk"
                        fill="#1A5468"
                        fill-rule="nonzero"
                        d="M146.64 0.96c-5.04,4.08 -9.38,9.32 -13.02,14.65 -7.35,10.78 -11.92,23.72 -10.43,36.88 1.1,9.76 5.4,18.86 11.18,26.7 0.35,0.47 1.42,0.67 1.91,0.76 1.01,0.19 2.16,0.23 3.19,0.13 0.55,-0.06 3.73,-0.43 2.89,-1.57 -5.2,-7.04 -9.13,-14.99 -10.57,-23.68 -1.13,-6.88 -0.64,-13.93 1.24,-20.64 2.95,-10.49 9.13,-19.95 16.59,-27.8 1.58,-1.66 3.22,-3.31 5,-4.76 1.13,-0.91 -2.6,-1.55 -2.89,-1.57 -1.6,-0.11 -3.77,-0.18 -5.09,0.9z"
                    />
                    <g ref="heroBody">
                        <path
                            ref="bodyLeft"
                            class="hero_body left"
                            fill="#F5BC5B"
                            fill-rule="nonzero"
                            d="M123.98 143.13c-0.84,-18.69 -4.05,-37.31 -9.91,-55.08 -1.6,-4.87 -3.41,-9.68 -5.41,-14.4 -1.48,-3.51 -2.99,-6.87 -5.29,-9.94 -2.84,-3.77 -6.61,-6.93 -11.17,-8.33 -6.37,-1.97 -13.13,-0.21 -18.85,2.84 -2.93,1.56 -5.2,3.46 -6.99,6.27 -3.99,6.25 -5.52,14.01 -6.46,21.25 -1.29,9.94 -1.26,20.06 -0.47,30.03 0.87,10.93 2.71,21.82 5.66,32.38 2.76,9.88 6.51,19.63 11.98,28.34 4.57,7.29 10.58,14.04 18.42,17.85 4.06,1.96 9.05,3.5 13.58,2.75 7.26,-1.22 12.19,-7.47 13.18,-14.54 1.81,-13.03 2.32,-26.28 1.73,-39.42z"
                        />
                        <path
                            ref="bodyBack"
                            class="hero_body back"
                            fill="#D49222"
                            fill-rule="nonzero"
                            d="M158.84 76.09c-0.15,-5.42 -0.43,-10.84 -0.99,-16.23 -0.41,-3.95 -0.92,-7.94 -1.96,-11.78 -0.33,-1.22 -0.71,-2.44 -1.27,-3.58 -1.46,-3.02 -3.77,-5.74 -7.03,-6.82 -2.06,-0.68 -4.13,-1.33 -6.21,-1.9 -6.69,-1.82 -13.8,-2.95 -20.72,-1.9 -7.46,1.13 -14.18,5.15 -17.64,12.03 -1.99,3.97 -2.88,8.5 -3.57,12.85 -0.98,6.21 -1.38,12.51 -1.52,18.8 -0.17,7.46 0.06,14.94 0.61,22.39 0.58,7.98 1.52,15.94 2.85,23.82 1.3,7.65 2.96,15.26 5.17,22.7 1.99,6.68 4.4,13.27 7.63,19.45 2.62,5.01 5.81,9.89 10.05,13.68 2.63,2.36 5.86,4.63 9.55,4.5 3.17,-0.11 5.92,-1.8 8.02,-4.1 2.64,-2.91 4.42,-6.59 5.87,-10.2 1.98,-4.89 3.4,-10.02 4.58,-15.16 1.41,-6.1 2.47,-12.28 3.34,-18.47 0.94,-6.78 1.65,-13.57 2.18,-20.39 0.53,-6.8 0.88,-13.61 1.06,-20.43 0.16,-6.42 0.18,-12.84 0,-19.26z"
                        />

                        <path
                            ref="bodyRight"
                            class="hero_body right"
                            fill="#F5BC5B"
                            fill-rule="nonzero"
                            d="M183.4 52.29c-0.25,-0.08 -0.5,-0.15 -0.74,-0.22 -15.31,-4.46 -30.85,4.4 -36.78,20.88 -10.7,29.74 -36.12,111.22 2.68,121.07 47.59,12.08 59.79,-134.06 34.84,-141.73z"
                        />
                        <path
                            ref="bodyFront"
                            class="hero_body front"
                            fill="#E8AB43"
                            fill-rule="nonzero"
                            d="M151.16 95.51c-0.21,-5.73 -0.55,-11.47 -1.18,-17.17 -0.49,-4.45 -1.11,-8.91 -2.2,-13.26 -0.71,-2.81 -1.55,-5.88 -3.3,-8.24 -0.61,-0.84 -1.37,-1.64 -2.35,-2.04 -2.43,-0.95 -4.79,-1.99 -7.28,-2.79 -3.19,-1.03 -6.48,-1.8 -9.81,-2.16 -7.83,-0.86 -16.09,0.65 -21.53,6.79 -3.67,4.14 -5.22,9.25 -6.22,14.58 -1.13,6.01 -1.57,12.16 -1.78,18.26 -0.26,7.62 -0.08,15.26 0.37,22.87 0.49,8.29 1.34,16.56 2.56,24.78 1.2,8.07 2.76,16.09 4.83,23.98 1.87,7.13 4.16,14.19 7.24,20.9 2.47,5.37 5.51,10.67 9.67,14.91 2.37,2.42 5.42,5.04 9.03,4.92 2.97,-0.09 5.39,-2.04 7.18,-4.25 2.36,-2.92 3.9,-6.51 5.17,-10 1.74,-4.77 2.98,-9.72 4.03,-14.69 1.25,-5.93 2.19,-11.91 2.95,-17.92 0.84,-6.56 1.45,-13.16 1.91,-19.76 0.46,-6.79 0.75,-13.58 0.87,-20.38 0.11,-6.44 0.08,-12.89 -0.16,-19.33z"
                        />
                    </g>
                    <g ref="legs">
                        <path
                            ref="legRight"
                            class="leg leg_right"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M184.11 200.09c-8.88,0.26 -20.18,1.61 -28.74,6.11 -1.18,0 -2.29,0.05 -3.3,0.19 0.29,-4.88 -0.16,-10.02 -0.12,-14.54 0.03,-4.02 1.32,-11.28 -1.57,-14.51 -0.99,-1.1 -3.16,-0.88 -3.82,0.49 -0.04,0.08 -0.07,0.15 -0.11,0.22 -0.36,0.75 -0.33,1.54 0.01,2.21 1.55,5 0.49,12.02 0.55,17.13 0.04,3.7 0.27,15.9 1.04,16.24 0.07,0.24 39.76,4.42 43.54,4.74 5.31,0.46 17.44,0.45 17.57,-7.33 0.19,-10.68 -18.4,-11.14 -25.05,-10.95z"
                        />
                        <path
                            ref="legLeft"
                            class="leg leg_left"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M101.5 178.36c-3.54,9.83 -4.49,20.8 -3.73,31.27 -10.6,-3.88 -22.44,-4.23 -33.52,-2.53 -4.37,0.66 -19.26,2.17 -18.19,10 0.81,5.92 9.46,5.17 13.49,4.97 12.92,-0.61 26.26,-2.41 38.67,-6.06 0.58,-0.17 3.74,-1.42 3.85,-2.98 0.81,-11.44 0.18,-22.37 4.3,-33.33 1.14,-3.03 -3.77,-4.39 -4.87,-1.34z"
                        />
                    </g>
                    <g ref="eyes">
                        <path
                            ref="eyeLeftWhite"
                            class="eye eye_left white"
                            fill="white"
                            fill-rule="nonzero"
                            d="M121.16 98.59c-0.5,7.11 -5.46,12.55 -11.07,12.16 -5.62,-0.4 -9.77,-6.48 -9.27,-13.58 0.49,-7.11 5.45,-12.55 11.06,-12.16 5.62,0.39 9.77,6.47 9.28,13.58z"
                        />
                        <path
                            ref="eyeLeft"
                            class="eye eye_left"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M118.46 101.94c0,4.13 -3.35,7.47 -7.47,7.47 -4.13,0 -7.47,-3.34 -7.47,-7.47 0,-4.13 3.34,-7.47 7.47,-7.47 4.12,0 7.47,3.34 7.47,7.47z"
                        />

                        <path
                            ref="eyeLeftBrow"
                            class="eye eye_left eyebrow"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M114.35 75.37c-1.7,-1.16 -4.46,-0.47 -6.3,0.12 -2.12,0.69 -4.06,1.81 -5.57,3.46 -0.97,1.05 0.31,2.97 1.6,2.08 1.6,-1.1 3.24,-2.05 5.15,-2.51 1.72,-0.42 3.5,0 5.12,-0.71 1.05,-0.47 0.78,-1.9 0,-2.44z"
                        />
                        <path
                            ref="eyeRightBrow"
                            class="eye eye_right eyebrow"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M138.15 79.42c-1.32,-0.37 -2.21,-1.6 -3.44,-2.23 -1.35,-0.71 -2.85,-1.03 -4.37,-1.1 -1.3,-0.06 -1.74,-2.12 -0.32,-2.39 1.93,-0.37 3.84,-0.23 5.68,0.5 1.55,0.62 3.78,1.76 4.14,3.54 0.19,0.99 -0.63,1.98 -1.69,1.68z"
                        />
                        <path
                            ref="eyeRightWhite"
                            class="eye eye_right white"
                            fill="white"
                            fill-rule="nonzero"
                            d="M144.32 98.59c-0.5,7.11 -5.45,12.55 -11.07,12.16 -5.62,-0.4 -9.77,-6.48 -9.27,-13.58 0.5,-7.11 5.45,-12.55 11.07,-12.16 5.61,0.39 9.76,6.47 9.27,13.58z"
                        />
                        <path
                            ref="eyeRight"
                            class="eye  eye_right"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M141.62 101.94c0,4.13 -3.35,7.47 -7.47,7.47 -4.13,0 -7.47,-3.34 -7.47,-7.47 0,-4.13 3.34,-7.47 7.47,-7.47 4.12,0 7.47,3.34 7.47,7.47z"
                        />
                    </g>
                    <path
                        ref="mouth"
                        class="mouth"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M142.2 131.88c-0.2,-1.43 -2.52,-1.07 -2.49,0.34 0.11,6.4 -1.14,12.5 -4.71,17.91 -4.54,6.88 -10.99,9.47 -19,9.96 -2.49,0.15 -2.46,4.11 0,3.87 8.68,-0.82 15.46,-3.18 20.78,-10.47 4.58,-6.28 6.48,-13.94 5.42,-21.61z"
                    />
                    <g ref="arms">
                        <path
                            ref="armLeft"
                            class="arm arm_left"
                            :class="{ say_hi: animationIs === 'sayHi' }"
                            fill="282F39"
                            fill-rule="nonzero"
                            d="M63.54 123.07c-15.42,-2.63 -28.05,-8.64 -40.04,-16.64 -2.65,-1.47 -3,-2.71 -3,-2.71 -0.51,-5.49 -1.77,-11.46 -5.16,-15.53 -0.69,-0.82 -2.33,-0.18 -2.21,0.92 0.31,2.82 1.34,5.42 2.01,8.16 0.31,1.26 0.54,2.54 0.74,3.81 -3.14,-2.34 -6.27,-4.81 -9.43,-7.37 -1.48,-1.21 -3.28,0.6 -2.09,2.09 2.93,3.62 6.28,6.9 9.92,9.9 -4.2,0.76 -8.38,0.45 -13,0.05 -1.32,-0.12 -1.75,1.85 -0.66,2.45 5.21,2.86 11.56,2.65 17.24,0.93 0.37,0.16 0.76,0.26 1.16,0.22 12.86,9.12 28.56,14.98 43.33,18.06 2.85,0.6 4.02,-3.86 1.19,-4.34z"
                        />
                        <path
                            ref="armRight"
                            class="arm arm_right"
                            fill="282F39"
                            fill-rule="nonzero"
                            d="M206.67 113.23c-8.53,-3.98 -20.21,-3.22 -27.04,3.63 -1.25,1.25 0.57,3.43 2.08,2.71 8.69,-4.15 17.45,-7.21 26.32,-1.15 3.81,2.61 6.96,6.51 8.61,10.84 1.4,3.68 0.62,7.8 2.06,11.31 0.06,0.14 0.16,0.27 0.26,0.39 -2.82,3.86 -5.02,10.25 -2.88,14.55 0.59,1.18 2.24,0.97 2.5,-0.33 0.46,-2.3 0.14,-4.62 0.79,-6.93 0.46,-1.62 1.18,-3.06 2.05,-4.44 0.51,1.23 1.1,2.45 1.52,3.6 0.92,2.47 1.55,5.19 2.97,7.42 0.55,0.85 2.17,0.47 2.18,-0.59 0.04,-2.62 -0.94,-5.24 -1.74,-7.71 -0.43,-1.3 -0.82,-2.82 -1.35,-4.26 1.01,0.45 1.96,1.05 2.94,1.93 2,1.78 3.45,4.03 5.3,5.95 1.03,1.07 2.69,0.18 2.25,-1.29 -1.42,-4.76 -8.43,-12.63 -14.1,-10.72 -1.11,-5.17 -0.43,-9.66 -3.52,-14.78 -2.65,-4.38 -6.56,-7.96 -11.2,-10.13z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </transition>
</template>
<script>
import { gsap } from "gsap";
export default {
    name: "Pepper",

    props: {
        size: {
            type: Number,
            required: false,
            default: 90,
        },

        animationIs: {
            type: String,
            required: false,
            default: null,
        },
    },

    methods: {
        onEnter() {
            if (!this.animationIs) return;
            if (this.animationIs === "squeeze") {
                this.squeeze();
                return;
            } else if (this.animationIs === "jump") {
                this.jump();
                return;
            } else if (this.animationIs === "sad") {
                this.sad();
                return;
            }
        },

        squeeze() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
            gsap.set(this.$refs.heroBody, { transformStyle: "preserve-3d" });
            tl.to(this.$refs.eyes, {
                y: -30,
                x: -20,
                scale: 2,
                duration: 0.5,
                ease: "bounce.out",
                z: 30,
            })
                .to(this.$refs.pepper, { rotationY: 45, duration: 0.1 }, 0)

                .to(this.$refs.arms, { scale: 0.8, x: 5, duration: 0 }, 0)

                .to(
                    this.$refs.eyes,
                    { y: 0, x: 0, scale: 1, duration: 0.3 },
                    0.7
                )

                .to(
                    this.$refs.pepper,
                    { rotationY: 0, skewX: 0, scaleX: 1, duration: 0.8 },
                    0.7
                )
                .to(this.$refs.arms, { scale: 1, x: 0, duration: 0.1 }, 0.7);
        },
        jump() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 4 });
            gsap.set(this.$refs.legLeft, { transformOrigin: "right top" });
            gsap.set(this.$refs.armLeft, {
                transformOrigin: "right",
                rotate: 0,
            });

            tl.to(
                this.$refs.legRight,
                {
                    x: 50,
                    duration: 0.4,
                },
                0
            )
                .to(
                    this.$refs.legLeft,
                    {
                        x: -50,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.bodyRight,
                    {
                        x: 40,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.bodyLeft,
                    {
                        x: -50,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.armLeft,
                    {
                        x: -40,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.armRight,
                    {
                        x: 40,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.pepper,
                    {
                        y: -30,
                        rotateY: 180,
                        duration: 0.8,
                    },
                    0
                )
                .to(
                    this.$refs.stalk,
                    {
                        rotate: 40,
                        scale: 0.8,
                        duration: 0.3,
                        x: 40,
                    },
                    0
                )
                .to(
                    this.$refs.legLeft,
                    {
                        duration: 0.3,
                        x: 0,
                    },
                    0.7
                )
                .to(
                    this.$refs.legRight,
                    {
                        duration: 0.3,
                        x: 0,
                    },
                    0.7
                )
                .to(
                    this.$refs.bodyRight,
                    {
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.bodyLeft,
                    {
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.stalk,
                    {
                        rotate: 0,
                        scale: 1,
                        x: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.pepper,
                    {
                        y: 0,
                        duration: 0.3,
                        rotateY: 360,
                    },
                    0.7
                );
        },
        sad() {
            var tl = gsap.timeline({ repeat: -1 });
            gsap.set(this.$refs.pepper, { transformStyle: "preserve-3d" });

            gsap.set(this.$refs.mouth, {
                rotate: "230deg",
                translateX: 10,
                translateY: 40,
                scale: 0.8,
            });

            gsap.set(this.$refs.armLeft, {
                rotate: "300deg",
                translateY: 100,
                translateX: 0,
            });
        },
    },
};
</script>

<style scoped>
* {
    overflow: visible;
}

.say_hi {
    z-index: 0;
    transform-origin: bottom right;
    transform: rotate(40deg) translateX(-40px) translateY(100px);
    animation: hi infinite 5s ease-in-out;
}

@keyframes hi {
    0% {
        transform: rotate(35deg) translateX(-40px) translateY(100px);
    }

    5% {
        transform: rotate(50deg) translateX(-30px) translateY(145px);
    }

    10% {
        transform: rotate(35deg) translateX(-40px) translateY(100px);
    }

    90% {
        transform: rotate(35deg) translateX(-40px) translateY(100px);
    }

    95% {
        transform: rotate(50deg) translateX(-30px) translateY(145px);
    }

    100% {
        transform: rotate(35deg) translateX(-40px) translateY(100px);
    }
}
</style>
